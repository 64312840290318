.summaryWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: var(--ds-spacing-sm) 0;
  @media (--ds-breakpoint-sm) {
    flex-direction: row;
    order: 1;
    width: 50%;
  }
  @media (--ds-breakpoint-md) {
    width: 100%;
  }
}

.summaryWrapper span {
  font-weight: 500;
  @media (--ds-breakpoint-sm) {
    text-align: left;
    width: 50%;
  }
}

.mediaQueryWrapper {
  @media (--ds-breakpoint-sm) {
    order: 3;
  }
}
