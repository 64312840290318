.cardCarouselSection {
  display: contents;
}

.cardCarousel {
  display: inline-flex;
  gap: var(--ds-spacing-xs);
  overflow-x: auto;
  width: 100%;
  container: card / inline-size;
  padding: var(--ds-spacing-2xs) 0;

  & > li {
    display: contents;
  }

  &:global(.scrollbarHorizontalDark)::-webkit-scrollbar,
  &:global(.scrollbarHorizontalLight)::-webkit-scrollbar {
    height: var(--ds-spacing-3xs);
  }
}

.singleCard {
  display: block;
}

.cardWrapper {
  display: flex;
  flex-direction: column;
  min-width: 23rem;
}

.cardContainer {
  display: inline-flex;
  padding: var(--ds-size-3xs) 0 var(--ds-size-3xs) 0;
  background-color: var(--ds-color-transparent-white-50);
  width: 100%;
  height: 100%;
  border-radius: var(--ds-border-radius-md);

  &:hover {
    text-decoration: none;
  }
}

@container card (width < 300px) {
  .cardWrapper {
    min-width: 90%;
  }

  .cardContainer {
    flex-direction: column;
  }
}

@container card (min-width: 300px) {
  .cardWrapper {
    min-width: 90%;
  }

  .cardContainer {
    flex-direction: row;
  }
}

@container card (min-width: 450px) {
  .cardWrapper {
    min-width: 30rem;
  }
}

.imageLogoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--ds-spacing-5xs);
  min-width: 8rem;

  & > picture {
    padding: var(--ds-spacing-3xs);
    gap: var(--ds-spacing-3xs);
  }

  & > logo {
    align-items: center;
  }
}

.detailsContainer {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--ds-spacing-4xs);
  padding: 0 var(--ds-spacing-xs) 0 var(--ds-spacing-xs);
  color: var(--_text-color, #fff);

  & > p {
    margin: 0;
  }
}

.titleWrapper {
  display: inline-flex;
  align-items: center;
  color: var(--_accent-text-color, #beedfc);
  gap: var(--ds-spacing-3xs);
}

.membersOnly {
  color: var(--ds-color-text-accent);
}

.visuallyHidden:not(:focus):not(:active) {
  /*
    we should ideally use visually-hidden from skroll utils,
    copying here as it clashes with global-styles in the component-library
  */
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.endDateQualifier {
  margin: 0;
  margin-top: var(--ds-spacing-3xs);
  color: var(--_text-color, #fff);
}

.qualifying-text {
  & > * {
    margin: 0;
    margin-bottom: var(--ds-spacing-4xs);
  }

  & :empty {
    display: none;
  }

  /* This makes a p that is BEFORE an ul/ol to be inlined. However, empty <p> or <br/> will make
  this rule to fail */

  & p:not(:empty) {
    &:has(+ ul),
    &:has(+ ol) {
      display: inline;
      margin-right: var(--ds-spacing-4xs);
    }
  }

  & ul,
  & ol {
    display: inline;

    & li {
      display: inline;

      &:after {
        content: ', ';
      }

      &:last-child:after {
        content: '';
      }

      & > * {
        display: inline;
        margin: 0;
        /* We might receive extra html like p or h2 inside the list items, and the typography classes might not get properly applied */
        letter-spacing: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
      }
    }
  }
}
