.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background: var(--_background-color);

  &:hover {
    text-decoration: none;
  }

  &.pointer {
    cursor: pointer;
  }
}

.imageWrapper {
  width: 100%;
  position: relative;
  animation: fadeAnimation 2s;
  aspect-ratio: 432/462;

  &.doubleImage {
    aspect-ratio: 864/462;
  }

  & > picture > img {
    width: 100%;
    height: 100%;
  }
}

.videoWrapper {
  aspect-ratio: 864/462;
  object-fit: fill;
  animation: fadeAnimation 2s;
  margin-bottom: -7px;
}

.mediaWrapper {
  width: 100%;
  position: relative;
}

.logoWrapper {
  position: absolute;
  z-index: 2;

  & img {
    object-fit: contain;
    max-height: 25px;
  }

  @media (--ds-breakpoint-md) {
    & img {
      max-width: 180px;
      max-height: 50px;
    }
  }
}

.ctaWrapper {
  max-width: max-content;
}

.textContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.contentContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--ds-spacing-xs);
}

.disruptorTitle {
  margin: 0;
  padding-bottom: var(--ds-spacing-sm);
  color: var(--_title-color);
  composes: ds-body-lg-bold from global;
}

.disruptorDescription {
  padding-bottom: var(--ds-spacing-sm);
  color: var(--_description-color);
  composes: ds-body-sm-regular from global;
}

@keyframes fadeAnimation {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.skeleton-animate {
  position: relative;
  overflow: hidden;
  background-color: var(--st-color-grey-light);

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);

    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }
}

.skeleton-image {
  composes: skeleton-animate;
  padding-top: calc(100% + var(--ds-spacing-sm));
}

.skeleton-text-box {
  padding: var(--ds-spacing-3xs);
  margin-bottom: auto;
}

.skeleton-text {
  composes: skeleton-animate;
  height: var(--ds-spacing-sm);
  margin-bottom: var(--ds-spacing-3xs);
  width: 60%;
  @media (--breakpoint-medium-min) {
    width: 100%;
  }

  & + .skeleton-text {
    width: 40%;
    @media (--breakpoint-medium-min) {
      width: 60%;
    }
  }
}

.skeleton-label:not(:focus):not(:focus-visible):not(:active) {
  /* We cannot use visually-hidden from skroll because there a class with the same name in GlobalStyles */
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.skeleton-label:focus-visible {
  box-shadow: 0 0 0 var(--ds-border-width-xs) var(--ds-color-focused-default) !important;
}

.skeleton-button {
  composes: skeleton-animate;
  height: var(--ds-size-xl);
  width: 10rem;
  border-radius: var(--ds-border-radius-round);
  margin-bottom: var(--ds-spacing-3xs);
  margin-left: var(--ds-spacing-3xs);
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
