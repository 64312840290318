.expanded-container {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  background: var(--_background-color, #092d49);
  padding: var(--ds-spacing-xl) var(--ds-spacing-xs) var(--ds-spacing-4xl)
    var(--ds-spacing-xs);
  align-items: flex-start;
  gap: var(--ds-spacing-xl);

  @media (--ds-breakpoint-md) {
    padding: var(--ds-spacing-xl) var(--ds-spacing-xl) var(--ds-spacing-4xl)
      var(--ds-spacing-xl);
    align-items: center;
  }
}

.expanded-title {
  color: var(--_accent-text-color, #beedfc);
  margin: 0 auto;

  @media (--ds-breakpoint-md) {
    margin-bottom: var(--ds-spacing-xl);
  }

  & > div {
    margin: 0 auto var(--ds-spacing-3xs);
  }
}

.expanded-cards {
  display: flex;
  flex-direction: column;
  gap: var(--ds-spacing-xl);

  @media (--ds-breakpoint-md) {
    max-width: 75%;
  }
}

.compact-container {
  display: flex;
  flex-direction: column;
  background: var(--_background-color, #092d49);
  gap: var(--ds-spacing-2xs);
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;

  @media (--ds-breakpoint-sm) {
    padding: var(--ds-spacing-2xs);
    justify-content: center;
    align-items: center;
  }
}

.compact-content-wrapper {
  display: contents;
  width: 100%;

  @media (--ds-breakpoint-md) {
    max-width: 80%;
  }
}

.compact-title-cards-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--ds-spacing-2xs);
  padding: var(--ds-spacing-xs);

  @media (--ds-breakpoint-sm) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: var(--ds-spacing-sm);
  }
}

.compact-header {
  display: flex;
  padding: 0 var(--ds-spacing-3xs);
  align-items: center;
  gap: var(--ds-spacing-2xs);
  align-self: stretch;
  justify-content: space-between;

  @media (--ds-breakpoint-sm) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--ds-size-3xs);
    flex: 1 0 0;
    padding-top: var(--ds-spacing-3xs);
  }
}

.compact-title {
  color: var(--_text-color, #fff);
  margin: 0;

  @media (--ds-breakpoint-lg) {
    text-wrap: nowrap; /*This is currently only supported in Chrome */
  }
}

.terms-link {
  text-decoration: underline;
  margin: 0;
  margin-left: auto;
  color: var(--_text-color, #fff);
  padding: var(--ds-spacing-3xs);
}

.single-card {
  padding: var(--ds-spacing-5xs);
  display: flex;
  flex-direction: row;

  & .compact-title {
    text-wrap: wrap; /*This is currently only supported in Chrome */
  }

  @media (--ds-breakpoint-sm) {
    padding: var(--ds-spacing-3xs);
  }

  @media (--ds-breakpoint-lg) {
    display: flex;
    align-items: center;
    padding: 0 var(--ds-spacing-md);
  }

  & .compact-content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (--ds-breakpoint-sm) {
      max-width: 90%;
    }

    @media (--ds-breakpoint-md) {
      max-width: 70%;
    }

    @media (--ds-breakpoint-lg) {
      flex: 2;
      max-width: unset;
      padding: var(--ds-spacing-2xs) 0;
    }
  }

  & .compact-title-cards-wrapper {
    @media (--ds-breakpoint-sm) {
      padding-top: var(--ds-spacing-3xs);
    }
  }

  & article {
    /* to avoid the card overflowing when there is only one */
    min-width: 100%;

    @media (--ds-breakpoint-sm) {
      min-width: 23rem;
    }
  }
}

.promo-image-container {
  display: none;

  @media (--ds-breakpoint-lg) {
    width: 100%;
    align-self: stretch;

    position: relative;
    overflow: hidden;

    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    max-width: 30rem;
  }

  & .promo-image-underlay {
    border-radius: 50%;
    background: var(--ds-color-transparent-white-50);
    z-index: 0;
    position: absolute;
    width: 100%;
    aspect-ratio: 1/1;
    left: 0;
    right: 0;
  }
}
