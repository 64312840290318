.grid {
  --_border: var(--ds-border-width-xs) solid var(--ds-color-border-subdued);
  width: 100%;
  min-width: min-content;
  display: grid;
  transition: all 900ms;
  border-top: var(--_border);
  border-left: var(--_border);
  overflow: hidden;

  &.no-border {
    --_border: none;
  }

  & .grid-item {
    width: var(--_item-width);
    height: var(--_item-height);
    border-bottom: var(--_border);
    border-right: var(--_border);
  }

  &:global(.single-column) {
    border-top: none;
    grid-template-columns: 100% 100%; /** Necessary for the smooth transition between single and double columns */

    & .grid-item {
      box-sizing: border-box;
      width: 50%; /** Necessary for the smooth transition between single and double columns */
      grid-column: span 2; /** Necessary for the smooth transition between single and double columns */

      &:first-of-type {
        border-top: var(--_border);
      }

    }

    & .row-width {
      grid-column: 1/-1;
      grid-row: var(--_grid-row-single, 2);
    }
  }

  grid-template-columns: 50% 50%;
  grid-auto-flow: dense;

  & .double-width {
    grid-column: auto/span 2;
  }

  & .row-width {
    grid-column: 1/-1;
    grid-row: var(--_grid-row, 2);
  }

  @media (--ds-breakpoint-md) {
    grid-template-columns: repeat(var(--_items-per-row-md, 3), 1fr);

    /*
    Single column design is only valid for small screens.
    To avoid using JS to dynamically toggle the class,
    we need to revert the styles in CSS inside the media query.
    */
    &:global(.single-column) {
      border-top: var(--_border);
      grid-template-columns: repeat(var(--_items-per-row-md, 3), 1fr);

      & .grid-item {
        width: 100%;
        grid-column: span 1;

        &:first-of-type {
          border-top: none;
        }
      }

      & .double-width {
        grid-column: auto/span 2;
      }

      & .row-width {
        grid-column: 1/-1;
        grid-row: var(--_grid-row, 2);
      }
    }

    & :nth-child(1 of .grid-disruptor) {
      grid-column-start: 2;
      grid-row-start: 1;
    }

    & :nth-child(2 of .grid-disruptor) {
      grid-column-start: 2;
      grid-row-start: 5;
    }

    & :nth-child(1 of .grid-disruptor-top) {
      grid-column-start: 2;
      grid-row-start: 1;
    }

    & :nth-child(1 of .grid-disruptor-bottom) {
      grid-column-start: 2;
      grid-row-start: 5;
    }
  }

  @media (--ds-breakpoint-lg) {
    grid-template-columns: repeat(var(--_items-per-row-lg, 3), 1fr);

    &:global(.single-column) {
      grid-template-columns: repeat(var(--_items-per-row-lg, 3), 1fr);
    }
  }

  @media (--ds-breakpoint-xl) {
    grid-template-columns: repeat(var(--_items-per-row-xl, 3), 1fr);

    &:global(.single-column) {
      grid-template-columns: repeat(var(--_items-per-row-xl, 3), 1fr);
    }
  }
}
