.container {
  width: 100%;
  max-width: var(--st-dimension-max-width);
  margin: 0 auto;
  margin-bottom: var(--ds-size-lg);

  & [id='product-listing-grid'] {
    margin-bottom: var(--ds-size-xl);
  }
}

.layout {
  @media (--ds-breakpoint-md) {
    display: flex;
  }
}

.list-wrapper {
  flex: 3 1 0%;
}

.back-to-top {
  text-align: center;
}
