.viewSwitch {
  display: flex;
  align-items: center;
  margin: 0 var(--ds-spacing-sm);
}

.viewSwitch button {
  height: 100%;
  padding: var(--ds-spacing-2xs);

  &:first-of-type {
    padding-left: var(--ds-spacing-sm);
  }
  &:last-of-type {
    padding-right: var(--ds-spacing-sm);
  }
}

.seperator {
  height: var(--ds-size-sm);
  width: var(--ds-size-4xs);
  background: var(--ds-color-layer-inverse);
}
