.card {
  color: var(--_text-color, var(--ds-color-text-inverse));
  border-radius: var(--ds-border-radius-md);
  padding: var(--ds-spacing-2xs);
  background: var(--ds-color-layer-static-light-hovered);

  @media (--ds-breakpoint-md) {
    padding: var(--ds-spacing-xs);
  }

  @media (--ds-breakpoint-lg) {
    display: flex;
    padding: var(--ds-spacing-lg) var(--ds-spacing-4xl);
    align-items: flex-start;
    justify-content: space-between;
  }
}

.product {
  margin-bottom: var(--ds-spacing-3xs);

  @media (--ds-breakpoint-lg) {
    flex: 0 0 45%;
    max-width: 45%;
  }

  & .product-image {
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: calc(4 / 3);

    & picture {
      width: 100%;
    }

    & img {
      max-height: 70vh;
    }

    @media (--ds-breakpoint-md) {
      justify-content: flex-start;
      aspect-ratio: unset;
    }
    @media (--ds-breakpoint-lg) {
      justify-content: center;
      aspect-ratio: calc(4 / 3);

      & img {
        max-height: 32rem;
      }
    }
  }
}

.logo img {
  display: block;
  margin-left: auto;
}

.title {
  composes: ds-label-lg-bold from global;
  color: var(--_accent-color, var(--ds-color-core-azur-100));

  display: flex;
  align-items: center;

  margin: 0;
  margin-bottom: var(--ds-spacing-2xs);

  & > div {
    margin-right: var(--ds-spacing-4xs);
  }
}

.product-title {
  margin: 0;
  margin-bottom: var(--ds-spacing-3xs);
}

.info-and-conditions {
  @media (--ds-breakpoint-lg) {
    flex: 0 0 50%;
  }
}

.product-info {
  composes: ds-body-lg-regular from global;

  display: flex;
  align-items: center;
  margin-bottom: var(--ds-spacing-2xs);

  & .product-data {
    display: inline-flex;
    align-items: center;

    margin-left: var(--ds-spacing-2xs);

    &:first-child {
      margin-left: 0;
    }

    & > div {
      margin-right: var(--ds-spacing-4xs);
    }
  }

  & .product-link {
    margin-left: auto;
    display: inline-flex;
    align-items: center;

    text-decoration: underline;
    color: var(--_text-color, var(--ds-color-text-inverse));

    & div {
      margin-left: var(--ds-spacing-3xs);
    }

    &:hover {
      text-decoration: none;
    }
  }
}

.product-description {
  composes: ds-body-xs-regular from global;
  margin: 0;
  margin-bottom: var(--ds-spacing-3xs);

  @media (--ds-breakpoint-lg) {
    margin-bottom: var(--ds-spacing-lg);
  }

  & button {
    color: var(--_text-color);
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

.conditions {
  background: var(--_info-bg-color);
  border-radius: var(--ds-border-radius-md);
  padding: var(--ds-spacing-2xs);

  @media (--ds-breakpoint-lg) {
    padding: var(--ds-spacing-xs);
  }

  & > p {
    margin: 0;
    margin-bottom: var(--ds-spacing-4xs);
  }

  & .qualifying-text {
    margin-bottom: var(--ds-spacing-3xs);

    & > * {
      margin: 0;
      margin-bottom: var(--ds-spacing-4xs);
    }

    & :empty {
      display: none;
    }

    & ul,
    & ol {
      margin-left: var(--ds-spacing-2xs);
      padding-left: var(--ds-spacing-2xs);
    }
    & ul li {
      list-style-type: disc;
    }
    & ol li {
      list-style-type: decimal;
    }
    & li > * {
      margin: 0;
      /* We might receive extra html like p or h2 inside the list items, and the typography classes might not get properly applied */
      letter-spacing: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
    }
  }

  & .members-only {
    color: var(--ds-color-text-accent);
  }
}

.cta-container {
  margin-top: var(--ds-spacing-2xs);

  & .user-ctas {
    & li {
      margin-bottom: var(--ds-spacing-3xs);
      &:last-child {
        margin-bottom: 0;
      }
    }
    & a {
      width: 100%;
      @media (--ds-breakpoint-sm) {
        width: fit-content;
      }
    }
  }

  & .extra-ctas {
    display: flex;
    flex-wrap: wrap;

    & li {
      margin-right: var(--ds-spacing-xs);
      margin-bottom: var(--ds-spacing-3xs);

      & a {
        width: fit-content;
        min-width: initial;
        height: auto;
        padding: var(--ds-spacing-3xs) var(--ds-spacing-2xs);
      }
    }
  }
}
